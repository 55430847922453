<template>
  <DefaultPage :title="$t('app.auth.authenticate.title')">
    <b-overlay :show="isLoading">
      <div class="p-2 m-auto" style="min-height: 100px; max-width: 400px">
        <div v-if="needsReInit">
          <b-button @click="initialize" class="w-100" variant="primary">
            {{ $t("app.auth.authenticate.initialize.buttonTitle") }}</b-button
          >
        </div>
        <div v-if="!needsReInit">
          <div class="mb-5 text-center" v-if="modes.length > 1">
            <b-form-radio-group
              v-model="selectedMode"
              :options="modes"
              button-variant="outline-primary"
              buttons
            ></b-form-radio-group>
          </div>

          <div>
            <div v-if="selectedMode == 'registerAnonymously'">
              <b-button
                @click="registerAnonymously"
                class="w-100"
                variant="primary"
                id="registerAnonymouslyButton"
              >
                {{
                  $t("app.auth.authenticate.registerAnonymously.buttonTitle")
                }}</b-button
              >
              <b-button
                @click="loginWithOAuth"
                class="w-100 mt-3"
                variant="primary"
                id="loginWithOAuthButton"
              >
                {{
                  $t("app.auth.authenticate.loginWithOAuth.buttonTitle")
                }}</b-button
              >
            </div>

            <div v-if="selectedMode == 'login'">
              <ValidationObserver v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(login)">
                  <ValidatedInput
                    :label="$t('app.auth.authenticate.login.email')"
                    name="email"
                    type="email"
                    autocomplete="username"
                    v-model="email"
                    rules="required|email"
                  />
                  <ValidatedInput
                    :label="$t('app.auth.authenticate.login.password')"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    v-model="password"
                    rules="required"
                  />
                  <b-button
                    variant="primary"
                    class="w-100"
                    id="loginButton"
                    type="submit"
                  >
                    {{ $t("app.auth.authenticate.login.buttonTitle") }}
                  </b-button>
                </b-form>
              </ValidationObserver>
            </div>

            <div v-if="selectedMode == 'register'">
              <ValidationObserver v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(register)">
                  <ValidatedInput
                    :label="$t('app.auth.authenticate.login.email')"
                    name="email"
                    type="email"
                    autocomplete="username"
                    v-model="email"
                    rules="required|email"
                  />
                  <ValidatedInput
                    :label="$t('app.auth.authenticate.login.password')"
                    name="password"
                    type="password"
                    autocomplete="new-password"
                    v-model="password"
                    rules="required"
                  />
                  <ValidatedCheckbox
                    :label="$t('app.auth.authenticate.register.acceptTos.tos')"
                    name="accept_tos"
                    type="checkbox"
                    v-model="acceptTos"
                    rules="required"
                    ><i18n
                      path="app.auth.authenticate.register.acceptTos.template"
                      tag="span"
                    >
                      <template v-slot:tos>
                        <b-link to="/tos">{{
                          $t("app.auth.authenticate.register.acceptTos.tos")
                        }}</b-link>
                      </template>
                      <template v-slot:privacy>
                        <b-link to="/privacy">{{
                          $t("app.auth.authenticate.register.acceptTos.privacy")
                        }}</b-link>
                      </template>
                    </i18n></ValidatedCheckbox
                  >
                  <b-button
                    variant="primary"
                    class="w-100"
                    id="registerButton"
                    type="submit"
                  >
                    {{ $t("app.auth.authenticate.register.buttonTitle") }}
                  </b-button>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </DefaultPage>
</template>

<script>
import ValidatedInput from "@/components/ValidatedInput.vue";
import ValidatedCheckbox from "@/components/ValidatedCheckbox.vue";
import CodeError from "@/models/CodeError";

export default {
  components: { ValidatedInput, ValidatedCheckbox },
  data() {
    return {
      selectedMode: null,
      modes: [],
      email: "",
      password: "",
      acceptTos: false,
    };
  },
  computed: {
    needsReInit() {
      return !this.isInitialized && !this.isLoading;
    },
    isLoading() {
      return this.$store.getters["auth/isAuthenticating"];
    },
    isInitialized() {
      return this.$store.getters["auth/isInitialized"];
    },
    isRegisterAnonymouslyAvailable() {
      return this.$store.getters["auth/isRegisterAnonymouslyAvailable"];
    },
  },
  created() {
    let modes = [];

    /* istanbul ignore next */
    if (this.isRegisterAnonymouslyAvailable) {
      modes.push({
        text: this.$t("app.auth.authenticate.modes.registerAnonymously"),
        value: "registerAnonymously",
      });
    } else {
      modes.push({
        text: this.$t("app.auth.authenticate.modes.login"),
        value: "login",
      });
      modes.push({
        text: this.$t("app.auth.authenticate.modes.register"),
        value: "register",
      });
    }

    this.modes = modes;
  },
  mounted() {
    if (this.$route.query.mode) {
      this.selectMode(this.$route.query.mode);
    } else {
      this.selectMode(this.modes[0].value);
    }

    if (!this.$store.state.auth.status.initialized) {
      this.initialize();
    }
  },
  methods: {
    selectMode(mode) {
      /* istanbul ignore else */
      if (this.modes.some((m) => m.value == mode)) {
        this.selectedMode = mode;
      } else {
        this.selectedMode = this.modes[0].value;
      }
    },
    async initialize() {
      try {
        await this.$store.dispatch("auth/init");
        this.redirect();
      } catch (error) {
        /* istanbul ignore next */
        if (error instanceof CodeError) {
          if (
            error.code == "Auth401UserAnonymous" &&
            this.isRegisterAnonymouslyAvailable
          ) {
            this.selectedMode("registerAnonymously");
            return;
          }
          if (error.code == "Auth401UserNotAnonymous") {
            this.selectMode("login");
            return;
          }
          if (error.code == "NonAuthUser") {
            return;
          }
        }
        if (!this.$store.state.auth.status.requireLoggedIn) {
          this.redirect();
        }
      }
    },
    /* istanbul ignore next */
    registerAnonymously() {
      this.$store.dispatch("auth/registerAnonymously").then(
        () => {
          this.redirect();
        },
        () => {
          this.$root.makeErrorToast(
            this.$t("app.auth.authenticate.registerAnonymously.errorMessage")
          );
        }
      );
    },
    login() {
      this.$store
        .dispatch("auth/login", { email: this.email, password: this.password })
        .then(
          () => {
            this.redirect();
          },
          /* istanbul ignore next */
          () => {
            this.$root.makeErrorToast(
              this.$t("app.auth.authenticate.login.errorMessage")
            );
          }
        );
    },
    register() {
      this.$store
        .dispatch("auth/register", {
          email: this.email,
          password: this.password,
        })
        .then(
          () => {
            this.redirect();
          },
          /* istanbul ignore next */ () => {
            this.$root.makeErrorToast(
              this.$t("app.auth.authenticate.register.errorMessage")
            );
          }
        );
    },
    /* istanbul ignore next */
    loginWithOAuth() {
      this.$store.dispatch("auth/loginWithOAuth").then(
        () => {
          this.redirect();
        },
        () => {
          this.$root.makeErrorToast(
            this.$t("app.auth.authenticate.login.errorMessage")
          );
        }
      );
    },
    redirect() {
      if (this.$route.query.redirectTo) {
        this.$router.replace(this.$route.query.redirectTo).catch(() => {});
      } else {
        this.$router.replace("/user/home").catch(() => {});
      }
    },
  },
};
</script>
